import axios from 'axios'
import addMilliseconds from 'date-fns/addMilliseconds'
import subDays from 'date-fns/subDays'
import { SignJWT } from 'jose'
import maxBy from 'lodash/maxBy'
import omit from 'lodash/omit'
import last from 'lodash/last'
import { DataItem } from '../stores/app'

export interface PgEvent {
  _id: string
  _modified: string
  _deleted: boolean
  source: string
  origin: string
  timestamp: string
  payload: Record<string, unknown>
}

interface EventResponse {
  events: DataItem[]
  lastUpdate?: string
}

interface ChangedEventsResponse {
  events: Array<DataItem & { _deleted: boolean }>
  lastUpdate: string
}

export async function getRecentEvents(source: string, password: string, days = 14): Promise<EventResponse> {
  const since = subDays(new Date(), days).toISOString()
  const secret = new TextEncoder().encode(password)
  const jwt = await new SignJWT({ role: 'peteci_user' })
    .setProtectedHeader({ alg: 'HS256' })
    .setExpirationTime('5m')
    .sign(secret)
  const resp = await axios.get<PgEvent[]>(
    `https://pg.probst.space/event?source=eq.${source}&order=timestamp.asc&timestamp=gt.${since}&_deleted=is.false`,
    { headers: { Authorization: `bearer ${jwt}`, 'Content-Type': 'application/json' } }
  )
  const lastUpdate = maxBy(resp.data, '_modified')?._modified
  return {
    events: resp.data.map((evt) => ({ ...omit(evt, ['_modified', '_deleted', 'payload', 'source']), ...evt.payload })),
    lastUpdate: lastUpdate ? addMilliseconds(new Date(lastUpdate), 1).toISOString() : undefined
  }
}

export async function getChangedEvents(
  modifiedSince: string,
  source: string,
  password: string
): Promise<ChangedEventsResponse> {
  const secret = new TextEncoder().encode(password)
  const jwt = await new SignJWT({ role: 'peteci_user' })
    .setProtectedHeader({ alg: 'HS256' })
    .setExpirationTime('5m')
    .sign(secret)
  const resp = await axios.get<PgEvent[]>(
    `https://pg.probst.space/event?source=eq.${source}&order=_modified.asc&_modified=gt.${modifiedSince}`,
    { headers: { Authorization: `bearer ${jwt}`, 'Content-Type': 'application/json' } }
  )
  const lastUpdate = last(resp.data)?._modified
  return {
    events: resp.data.map((evt) => ({ ...omit(evt, ['_modified', 'payload', 'source']), ...evt.payload })),
    lastUpdate: lastUpdate ? addMilliseconds(new Date(lastUpdate), 1).toISOString() : modifiedSince
  }
}
