import React from 'react'
import { Main } from './components/Main'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { getConfigs, setConfigs } from './lib/storage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />
  },
  {
    path: '/:tab',
    element: <Main />
  }
])

function App() {
  return <RouterProvider router={router} />
}

declare module window {
  export let shareConfig: () => void
  export let resetConfig: () => void
}

window.resetConfig = function () {
  setConfigs([])
}

window.shareConfig = function () {
  const configs = getConfigs()
  console.log(`?cfg=${encodeURIComponent(JSON.stringify(configs))}`)
}

export default App
