import { Button, Switch, Tag, Typography } from 'antd'
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { DbState } from '../lib/Database'
import { GraphConfig, storeViewConfig, updateConfig, ViewConfig } from '../lib/storage'
import { useAppStore } from '../stores/app'
import EditGraphModal from './EditGraphModal'
import Graph from './Graph'
import range from 'lodash/range'
import isEqual from 'lodash/isEqual'

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Panel = styled.div`
  width: 360px;
  height: 100%;
  overflow: scroll;
  padding: 8px;
  display: flex;
  flex-direction: column;
`

const TopControls = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

const GridGrow = styled.div`
  display: grid;
  flex-grow: 1;
`

const Legend = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LineSelector = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 8px 0;
`

const Circle = styled.div<{ color: string }>`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
`

const Square = styled.div<{ color: string }>`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  border: 1px solid #aaa;
  background-color: ${({ color }) => color.substring(0, 7) + '1b'};
`

const Label = styled(Typography.Text)`
  flex-grow: 1;
`

const dbStates: Record<DbState, { label: string; color: string }> = {
  init: { label: 'Verbinde...', color: 'cyan' },
  sync: { label: 'Synchronisiere...', color: 'lime' },
  ready: { label: 'Aktuell', color: 'green' },
  error: { label: 'Fehler', color: 'red' }
}

const defaultZoomScale = 3 * 24 * 60 * 60 * 1000

export default function GraphContainer({ index: dbIndex }: { index: number }): ReactElement | null {
  const [dataCache, fetchData, configs, reloadConfigs, viewConfig, reloadViewConfig] = useAppStore((state) => [
    state.dataCache,
    state.fetchData,
    state.configs,
    state.reloadConfigs,
    state.viewConfig,
    state.reloadViewConfig
  ])

  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [dbState, setDbState] = useState<DbState>('init')
  const [isLandscape, setLandscape] = useState(
    !window.screen.orientation || window.screen.orientation.type.startsWith('landscape')
  )
  const [zoomScale, setZoomScale] = useState(viewConfig.zoomScale[dbIndex] ?? defaultZoomScale)

  const data = dataCache[dbIndex]
  const config = configs[dbIndex]
  const initialZoomScale = viewConfig.zoomScale[dbIndex] ?? defaultZoomScale

  const [selectedLines, setSelectedLines] = useState(
    viewConfig.selectedLines[dbIndex]?.length ? viewConfig.selectedLines[dbIndex] : range(config.lines.length)
  )

  useEffect(() => {
    setDbState('init')
    fetchData(dbIndex)
      .then(() => {
        setDbState('ready')
      })
      .catch(() => {
        setDbState('error')
      })
  }, [dbIndex, fetchData])

  const handleZoomScaleChange = useCallback((newZoomScale: number) => {
    setZoomScale(newZoomScale)
  }, [])

  useEffect(() => {
    if (!window.screen.orientation) return
    function handleOrientationChange(): void {
      setLandscape(window.screen.orientation.type.startsWith('landscape'))
    }
    window.addEventListener('orientationchange', handleOrientationChange)
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])

  function handleSwitch(index: number): void {
    if (selectedLines.includes(index)) {
      setSelectedLines((previous) => previous.filter((lineIndex) => lineIndex !== index))
    } else {
      setSelectedLines((previous) => [...previous, index].sort())
    }
  }

  function handleConfigChange(update: { lines: GraphConfig['lines'] }): void {
    updateConfig(dbIndex, { ...config, ...update })
    reloadConfigs()
    setEditModalOpen(false)
  }

  function handleSave(): void {
    const updatedSelectedLines = [...viewConfig.selectedLines]
    updatedSelectedLines[dbIndex] = selectedLines
    const updatedZoomScale = [...viewConfig.zoomScale]
    updatedZoomScale[dbIndex] = zoomScale

    const updatedViewConfig: ViewConfig = {
      tabKey: (dbIndex + 1).toString(),
      selectedLines: updatedSelectedLines.map((lines) => lines ?? []),
      zoomScale: updatedZoomScale.map((scale) => scale ?? defaultZoomScale)
    }

    storeViewConfig(updatedViewConfig)
    reloadViewConfig()
  }

  const hasViewConfigChanges =
    viewConfig.tabKey !== (dbIndex + 1).toString() ||
    !isEqual(viewConfig.selectedLines[dbIndex], selectedLines) ||
    initialZoomScale !== zoomScale

  return (
    <Container>
      {data ? (
        <Graph
          data={data}
          config={config}
          selectedLines={selectedLines}
          initialZoomScale={initialZoomScale}
          onZoomScaleChange={handleZoomScaleChange}
        />
      ) : (
        <GridGrow>
          <Typography.Text style={{ justifySelf: 'center' }}>Bitte warten...</Typography.Text>
        </GridGrow>
      )}
      {isLandscape && (
        <Panel>
          <TopControls>
            <GridGrow>
              <Tag style={{ justifySelf: 'start' }} color={dbStates[dbState].color}>
                {dbStates[dbState].label}
              </Tag>
            </GridGrow>
            <Button
              type="default"
              icon={<EditOutlined />}
              size="small"
              shape="circle"
              onClick={() => setEditModalOpen(true)}
            />
            <Button
              type="default"
              disabled={!hasViewConfigChanges}
              icon={<SaveOutlined />}
              size="small"
              shape="circle"
              onClick={handleSave}
            />
          </TopControls>
          <Legend>
            {config.lines.map((line, index) => (
              <LineSelector key={`ls_${index}`}>
                {line.binary ? <Square color={line.color} /> : <Circle color={line.color} />}
                <Label>{line.label}</Label>
                <Switch checked={selectedLines.includes(index)} onChange={() => handleSwitch(index)}></Switch>
              </LineSelector>
            ))}
          </Legend>
        </Panel>
      )}
      <EditGraphModal
        isOpen={isEditModalOpen}
        onOk={handleConfigChange}
        onCancel={() => setEditModalOpen(false)}
        config={config}
      />
    </Container>
  )
}
