import { Tabs } from 'antd'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { addConfig, GraphConfig, setConfigs } from '../lib/storage'
import { useAppStore } from '../stores/app'
import AddGraphModal from './AddGraphModal'
import GraphContainer from './GraphContainer'

const mobileMode = window.innerWidth < 400 || window.innerHeight < 400

const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: ${mobileMode ? '4px' : '32px'};
`

export function Main(): JSX.Element {
  const [configs, reloadConfigs, viewConfig] = useAppStore((state) => [
    state.configs,
    state.reloadConfigs,
    state.viewConfig
  ])
  const [isAddGraphModalOpen, setAddGraphModalOpen] = useState(false)
  const [tabKey, setTabKey] = useState(viewConfig.tabKey)
  const [queryParams] = useSearchParams()

  if (queryParams.has('cfg') && !configs.length) {
    setConfigs(JSON.parse(queryParams.get('cfg') ?? '[]'))
    reloadConfigs()
  }

  const tabs = configs.map((config, index) => ({
    key: String(index + 1),
    label: config.name,
    children: <GraphContainer index={index} />,
    closable: false
  }))

  function addNewGraph(data: Omit<GraphConfig, 'lines'>): void {
    addConfig({ ...data, lines: [] })
    reloadConfigs()
    setTabKey((configs.length + 1).toString())
  }

  return (
    <Layout>
      <Tabs
        type="editable-card"
        size="small"
        tabBarGutter={12}
        destroyInactiveTabPane
        items={tabs}
        activeKey={tabKey}
        onChange={(key) => setTabKey(key)}
        onEdit={() => setAddGraphModalOpen(true)}
      />
      <AddGraphModal isOpen={isAddGraphModalOpen} onOk={addNewGraph} onCancel={() => setAddGraphModalOpen(false)} />
    </Layout>
  )
}
