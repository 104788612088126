import React from 'react'
import ReactDOM from 'react-dom/client'
import { createGlobalStyle } from 'styled-components'
import App from './App'
import reportWebVitals from './reportWebVitals'

const GlobalStyle = createGlobalStyle`
  html,body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .ant-tabs,.ant-tabs-content,.ant-tabs-tabpane {
    flex-grow: 1;
    max-height: 100%;
  }
  .ant-tabs-content,.ant-tabs-tabpane {
    height: 100%;
  }
`

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
