enum StorageKey {
  configs = 'db_configs',
  views = 'view_config'
}

export interface GraphLineConfig {
  color: string
  label: string
  property: string
  filterCondition?: string
  binary: boolean
}

export interface GraphConfig {
  database: string
  name: string
  password: string
  lines: GraphLineConfig[]
}

export interface ViewConfig {
  tabKey: string
  selectedLines: number[][]
  zoomScale: number[]
}

export function getConfigs(): GraphConfig[] {
  const item = localStorage.getItem(StorageKey.configs)
  if (!item) return []
  return JSON.parse(item)
}

export function getViewConfig(): ViewConfig {
  const item = localStorage.getItem(StorageKey.views)
  if (!item) return { tabKey: '1', selectedLines: [], zoomScale: [] }
  return Object.assign({ tabKey: '1', selectedLines: [], zoomScale: [] }, JSON.parse(item))
}

export function storeViewConfig(viewConfig: ViewConfig): void {
  localStorage.setItem(StorageKey.views, JSON.stringify(viewConfig))
}

export function updateConfig(index: number, config: GraphConfig) {
  const configs = getConfigs()
  configs[index] = config
  localStorage.setItem(StorageKey.configs, JSON.stringify(configs))
}

export function addConfig(config: GraphConfig): void {
  const configs = getConfigs()
  configs.push(config)
  localStorage.setItem(StorageKey.configs, JSON.stringify(configs))
}

export function setConfigs(configs: GraphConfig[]): void {
  localStorage.setItem(StorageKey.configs, JSON.stringify(configs))
}

export function deleteConfig(index: number) {
  const configs = getConfigs()
  configs.splice(index, 1)
  localStorage.setItem(StorageKey.configs, JSON.stringify(configs))
}
